import * as cityActionTypes from "./cityActionTypes";

const initialState = {
    deliveryList: [],
};

const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case cityActionTypes.LOAD_DELIVERY_OPTIONS:
      return {
        ...state,
        deliveryList: action.value,
      };
    case cityActionTypes.CLEAR_DELIVERY_OPTIONS:
      return {
        deliveryList: [],
      };
    default:
      return state;
  }
};

export default deliveryReducer;
