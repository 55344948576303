import { combineReducers } from "redux";
import countryReducer from "./country/countryReducer";
import provinceReducer from "./province/provinceReducer";
import cityReducer from "./city/cityReducer";
import areaReducer from "./area/areaReducer";
import breadcrumbReducer from "./breadcrumb/breadcrumbReducer";
import notificationReducer from "./notification/notificaitonReducer";
import spinnerReducer from "./spinner/spinnerReducer";
import changesLogReducer from "./changelog/changeLogReducer";
import authReducer from "./auth/authReducer";
import deliveryReducer from "./city/deliveryReducer";

const rootReducer = combineReducers({
  country: countryReducer,
  province: provinceReducer,
  city: cityReducer,
  area: areaReducer,
  breadcrumb: breadcrumbReducer,
  notification: notificationReducer,
  spinner: spinnerReducer,
  changelog: changesLogReducer,
  auth: authReducer,
  delivery:deliveryReducer
});

export default rootReducer;
