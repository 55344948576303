import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadCity } from "../../../store/city/cityActions";
import ActionMenu from "../../actionmenu/Actionmenu";
import BreadcrumbIndex from "../../breadcrumb/BreadcrumbIndex";
import CityListTable from "./CityList";

export default function CityIndex() {
  const cityList = useSelector((state) => state.city.cityList);
  const deliveryList = useSelector((state) => state.delivery.deliveryList);
  const dispatch = useDispatch();
  const history = useHistory();

  const country = localStorage.getItem("country");
  const province = localStorage.getItem("province");

  const breadcrumb = [
    { title: "Home", url: "/mapper/country" },
    { title: country, url: "/mapper/province" },
    { title: province, url: "/mapper/city" },
  ];
  localStorage.setItem("breadcrumb", JSON.stringify(breadcrumb));

  useEffect(() => {
    const country = localStorage.getItem("country");
    const provinceId = localStorage.getItem("provinceId");
    dispatch(loadCity(country, provinceId));
  }, [dispatch]);

  const onRowSelected = (city) => {
    localStorage.setItem("cityId", city.id);
    localStorage.setItem("city", city.name_en);
    history.push("/mapper/area");
  };

  return (
    <div>
      <BreadcrumbIndex />
      <ActionMenu />
      <CityListTable tabaledata={cityList} rowSelected={onRowSelected} deliveryList={deliveryList} />
    </div>
  );
}
