import React, { useEffect, useState } from "react";
import { Button, Fab, Grid, Box} from "@material-ui/core";
import { publishMessage } from "../../../store/notification/notificationActions";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getHours, getMinutes, setMinutes, setHours } from "date-fns";
import {
  hideSpinner,
  showSpinner,
} from "../../../store/spinner/spinnerActions";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DataGrid } from "@material-ui/data-grid";
import defaultPaymentList from "./payments.json";
import fetchFastDeliveryPayment, {
  updateFastDeliveryPayment,
} from "../../../store/fastDeliveryPayment/actions";
import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";

export default function FastDeliveryPayment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultMap = defaultPaymentList.map((p) => ({
    code: p.code,
    label: p.label,
    selected: false,
  }));
  const [paymentData, setPaymentData] = useState(defaultMap);
  const [deliveryOpt, setDeliveryData] = useState([]);
  const [selectedMethods, setSelected] = useState([]);
  const [selectedDateSdd, setDateSdd] = useState(new Date(2021, 3, 21, 23, 59, 59));
  const [selectedDateNdd, setDateNdd] = useState(new Date(2021, 3, 21, 23, 59, 59));
  
  const [selectedTime,setTime] = useState([]);

  const selectedDates = {
    ndd: selectedDateNdd,
    sdd: selectedDateSdd,
  };

  const setSelectedDates = {
    ndd: setDateNdd,
    sdd: setDateSdd,
  };

  const goback = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(showSpinner());
    fetchFastDeliveryPayment().then((response) => {
      const { data, statusCode, msg,deliveryOption } = response;
      const { paymentData: pData, selectedTime: pTime } = data || {};
      if (pData && pData.length) {
        let newPaymentOpt = pData;
        if (paymentData?.length) {
          newPaymentOpt = paymentData.map((v) => ({
            ...v,
            ...pData.find((sp) => sp.code === v.code),
          }));
        }
        setPaymentData(newPaymentOpt);
        setDeliveryData(deliveryOption)
      }
      if (pTime) {
        setTime(pTime);
        setDateFromTime(pTime.find(item => item.sdd)?.sdd,'sdd');
        setDateFromTime(pTime.find(item => item.ndd)?.ndd,'ndd');
      }
      const severity = statusCode === 200 ? "success" : "error";
      dispatch(hideSpinner());
      if (statusCode !== 403) {
        dispatch(
          publishMessage({
            message: msg,
            severity,
          }),
        );
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (paymentData.length) {
      const sMethods = paymentData
        .filter((p) => {
          return p.selected;
        })
        .map((m) => m.code);
      setSelected(sMethods);
    }
  }, [paymentData]);

  const updateSelection = (data) => {
    const sData = data.selectionModel;
    const pData = paymentData;
    const updatedData = pData.map((p) => {
      return { ...p, selected: sData.indexOf(p.code) > -1 };
    });
    setPaymentData(updatedData);
  };

  const setDateFromTime = (time,key) => {
    if (time) {
      const timeArr = time.split(":");
      const hours = Number(timeArr[0]);
      const minutes = Number(timeArr[1]);
      let result = setMinutes(new Date(), minutes);
      result = setHours(result, hours);
      setSelectedDates[key](result);
    }
  };

  const saveSelection = async () => {
    dispatch(showSpinner());
    const response = await updateFastDeliveryPayment({
      paymentData,
      selectedTime,
    });
    const { statusCode, msg } = response;
    const severity = statusCode === 200 ? "success" : "error";
    dispatch(hideSpinner());
    if (statusCode !== 403) {
      dispatch(
        publishMessage({
          message: msg,
          severity,
        }),
      );
    }
  };

  const handleTimeChange = (time,keyData) => {
    let timeData = selectedTime;
    const hours = getHours(time);
    const minutes = getMinutes(time);
    const t = `${hours}:${minutes}`;
    const filterArray = timeData.length > 0 ?  timeData?.filter((item) => !item.hasOwnProperty(keyData)) : [];
    filterArray.push({ [keyData]: t });
    setTime(filterArray);
    setSelectedDates[keyData](time);
  };
  const columns = [
    { field: "id", headerName: "Payment Code", width: 200 },
    { field: "label", headerName: "Payment Label", width: 300 },
  ];

  const rows = paymentData.map((pItem) => {
    return {
      id: pItem.code,
      label: pItem.label,
    };
  });
  return (
    <>
      <Box width="100%">
        <Grid container>
          <Grid item md={1}>
            <Fab
              color="primary"
              aria-label="edit"
              size="small"
              variant="extended"
              onClick={goback}
            >
              <ArrowBackIcon />
            </Fab>
          </Grid>
        </Grid>
        <Grid container></Grid>

        <Grid container display="flex">
          <Grid item md={6} container alignItems="center" justify="center">
            <Grid item md={12}>
              <h1>Choose fast delivery payment options</h1>
            </Grid>

            <Grid item md={7} style={{ height: 400 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                onSelectionModelChange={updateSelection}
                selectionModel={selectedMethods}
                checkboxSelection
              />
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid item md={12} container alignItems="center" justify="center">
              <Grid item md={12}>
                <h1>Select Time threshold</h1>
              </Grid>
              {
                deliveryOpt.map((m,key)=>
                <>
               <Grid item md={6}>
                {m.label}
                </Grid>
                <Grid item md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardTimePicker
                      margin="normal"
                      id={`time-picker-${key}`}
                      label={`${m.key.toUpperCase()} Time Threshold`}
                      value={selectedDates[m.key]}
                      onChange={(e)=>handleTimeChange(e,m.key)}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              </>
                )
              }
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Button variant="contained" color="primary" onClick={saveSelection}>
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
